export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    // { label: "账号等级", prop: "grade", slots: "grade" },
    { label: "账号", prop: "sup_username", width: 185 },
    { label: "密码", prop: "sup_password", width: 185 },
    // { label: "手机号", prop: "supplier_phone" },
    { label: "分配门店", prop: "store_id", slots: "store_id", width: 185 },
    // { label: "登录异常", prop: "error", slots: "error", width: 100 },
    { label: "绑定微信openid", prop: "openid", slots: "openid", width: 185 },
    // {
    //   label: "微信绑定",
    //   prop: "saoma",
    //   slots: "saoma",
    //   width: 100,
    // },
    { label: "备注", prop: "message", width: 185, },
    {
      label: "创建时间",
      prop: "create_time",
      width: 185,
    },
    {
      label: "账号有效期",
      prop: "vip_date",
      width: 185,
    },
    {
      label: "操作",
      prop: "operation",
      width: 185,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
