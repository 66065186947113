import axios from "axios";
import { request } from "@/utils/request";

/**
 * 查询
 * @param {*} data
 * @returns
 */
export function selectAdmin(data) {
  return request({
    url: `/admin/sup/select`,
    method: "POST",
    data,
  });
}
/**
 * 用户查询自己管理的账号
 * @param {*} data
 * @returns
 */
export function selectById(data) {
  return request({
    url: `/admin/sup/selectById`,
    method: "POST",
    data,
  });
}
/**
 * 新增
 * @param {*} data
 * @returns
 */
export function addAdmin(data) {
  return request({
    url: `/admin/sup/add`,
    method: "POST",
    data,
  });
}

/**
 * 更改
 * @param {*} data
 * @returns
 */
export function updateAdmin(data) {
  return request({
    url: `/admin/sup/update`,
    method: "POST",
    data,
  });
}

/**
 * 编辑权限
 * @param {*} data
 * -role_id
 * @returns
 */
export function updateRole(data) {
  return request({
    url: `/admin/sup/updateRole`,
    method: "POST",
    data,
  });
}
/**
 * 删除
 * @param {*} data
 * @returns
 */
export function deleteAdmin(data) {
  return request({
    url: `/admin/sup/delete`,
    method: "POST",
    data,
  });
}
